import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ProfilePicture from '../components/profilePicture';

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />
    <div style={{ maxWidth: '25rem' }}>
      <ProfilePicture />
    </div>
  </Layout>
);

export default IndexPage;
